<template>
  <div class="helpdesk">
    <h1 class="titulo-card fs-4">Helpdesk</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="modalNovoTicketAbreHandle()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'magic']" fixed-width class="me-1"/>Novo chamado
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="carregaTickets()"
              disabled
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button disabled v-on:click="carregaTickets()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <!-- mobile menu -->
        <div class="row w-100 m-0 p-1 pb-2 justify-content-between small border-bottom show-mobile">
          <div class="col-auto p-2">
            <button @click="exibeMeusTickets()" type="button" class="btn btn-outline-secondary position-relative rounded-0 p-3">
              <font-awesome-icon :icon="['far', 'user']" fixed-width size="2x"/>
              <span v-if="counters.mine > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                {{counters.mine}}
              </span>
            </button>
          </div>
          <div class="col-auto p-2">
            <button @click="exibeTicketsDoMeuGrupo()" type="button" class="btn btn-outline-secondary position-relative rounded-0 p-3">
              <font-awesome-icon :icon="['fas', 'users']" fixed-width size="2x"/>
              <span v-if="counters.myGroup > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                {{counters.myGroup}}
              </span>
            </button>
          </div>
          <div class="col-auto p-2">
            <button @click="exibeTicketsAtrasados()" type="button" class="btn btn-outline-secondary position-relative rounded-0 p-3">
              <font-awesome-icon :icon="['fas', 'exclamation']" fixed-width size="2x"/>
              <span v-if="counters.myLate > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                {{counters.myLate}}
              </span>
            </button>
          </div>
          <!-- <div class="col"></div> -->
          <div class="col-auto p-2">
            <button @click="showMenu = !showMenu" type="button" class="btn btn-outline-secondary rounded-0 p-3">
              <font-awesome-icon :icon="['fas', 'bars']" fixed-width size="2x"/>
            </button>
          </div>
        </div>
      </div>
        <!-- mobile menu -->

      <div class="d-flex">
        <div class="row m-0 w-100">
          <div :class="{'hide-mobile': !showMenu}" class="col-auto small lista-grupos">
            <table class="table table-sm-pc table-striped table-hover helpdesk-menu">
              <tbody>
                <tr v-on:click="exibeMeusTickets()"
                    v-bind:class="classeGrupoSelecionado('teMeusTickets', 0)"
                    class="cursor-pointer item-grupo">
                  <td width="25">
                    <font-awesome-icon :icon="['fas', 'user-tag']"/>
                  </td>
                  <td>Meus chamados</td>
                  <td width="15">
                    <span class="badge rounded-pill bg-primary">
                      {{counters.mine}}
                    </span>
                  </td>
                </tr>
                <tr v-on:click="exibeTicketsDoMeuGrupo()"
                    v-bind:class="classeGrupoSelecionado('teDoMeuGrupo', 0)"
                    class="cursor-pointer item-grupo">
                  <td>
                    <font-awesome-icon :icon="['fas', 'user-friends']"/>
                  </td>
                  <td>Do meu grupo</td>
                  <td>
                    <span class="badge rounded-pill bg-info">
                      {{counters.myGroup}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-sm-pc table-striped table-hover helpdesk-menu">
              <tbody>
                <tr v-for="grupo in listaGrupos"
                    :key="grupo.id"
                    v-on:click="exibeTicketsDoGrupo(grupo.id)"
                    v-bind:class="classeGrupoSelecionado('teGrupo', grupo.id)"
                    class="cursor-pointer item-grupo">
                  <td width="25">
                    <font-awesome-icon :icon="['fas', 'users']"/>
                  </td>
                  <td>{{grupo.description}}</td>
                  <td width="15">
                    <span class="badge rounded-pill bg-secondary">
                      {{contadorGrupo(grupo.id)}}
                    </span>
                  </td>
                </tr>
                <tr v-on:click="exibeTicketsDoGrupo('')" v-bind:class="classeGrupoSelecionado('teGrupo', '')" class="cursor-pointer item-grupo">
                  <td width="25">
                    <font-awesome-icon :icon="['fas', 'users']"/>
                  </td>
                  <td>Não-classificados</td>
                  <td width="15">
                    <span class="badge rounded-pill bg-secondary">
                      {{counters.ungrouped}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-sm-pc table-striped table-hover helpdesk-menu">
              <tbody>
                <tr v-on:click="exibeTicketsAbertos()"
                    v-bind:class="classeGrupoSelecionado('pending', 0)"
                    class="cursor-pointer item-grupo">
                  <td width="25"><font-awesome-icon :icon="['fas', 'tags']"/></td>
                  <td>Abertos</td>
                  <td width="15">
                    <span class="badge rounded-pill float-end bg-secondary">
                      {{counters.pending}}
                    </span>
                  </td>
                </tr>
                <tr v-on:click="exibeTicketsAguardando()"
                    v-bind:class="classeGrupoSelecionado('waitingFor', 0)"
                    class="cursor-pointer item-grupo">
                  <td><font-awesome-icon :icon="['fas', 'tags']"/></td>
                  <td>Aguardando</td>
                  <td>
                    <span class="badge rounded-pill float-end bg-secondary">
                      {{counters.waitingFor}}
                    </span>
                  </td>
                </tr>
                <tr v-on:click="exibeTicketsAtrasados()"
                    v-bind:class="classeGrupoSelecionado('teAtrasados', 0)"
                    class="cursor-pointer item-grupo">
                  <td><font-awesome-icon :icon="['fas', 'stopwatch']"/></td>
                  <td>Atrasados</td>
                  <td>
                    <span v-bind:class="{'bg-secondary': !counters.tickets_atrasados, 'bg-warning': counters.tickets_atrasados}" class="badge rounded-pill float-end">
                      {{counters.myLate || '0'}} / {{counters.late || '0'}}
                    </span>
                  </td>
                </tr>
                <tr v-on:click="exibeTicketsEncerrados()"
                    v-bind:class="classeGrupoSelecionado('done', 0)"
                    class="cursor-pointer item-grupo">
                  <td><font-awesome-icon :icon="['fas', 'check-double']"/></td>
                  <td>Encerrados</td>
                  <td>
                    <span class="badge rounded-pill float-end bg-secondary">
                      {{counters.done}}
                    </span>
                  </td>
                </tr>
                <tr v-on:click="exibeHistoricoTickets()"
                    v-bind:class="classeGrupoSelecionado('teHistorico', 0)"
                    class="cursor-pointer item-grupo">
                  <td><font-awesome-icon :icon="['fas', 'history']"/></td>
                  <td>Histórico</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col">

            <!-- TABELA 1 -->
            <table class="table table-sm-pc table-striped table-hover tabela-tickets small">
              <thead>
                <tr>
                  <th width="35" class="text-center">
                    <div class="form-check mb-0">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input cursor-pointer mt-2">
                      </label>
                    </div>
                  </th>
                  <th>Cliente</th>
                  <th>Chamado</th>
                  <th width="85">Importância</th>
                  <th width="85">Status</th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="ticket in tickets" :key="ticket.id" class="item-ticket">
                    <td class="text-center" scope="row">
                      <div class="form-check mb-0">
                        <label class="form-check-label">
                          <input v-model="ticket._checked" type="checkbox" class="form-check-input cursor-pointer mt-2">
                        </label>
                      </div>
                    </td>
                    <td v-on:click="visualizaTicket(ticket)" class="cursor-pointer">
                      <div class="row g-1">
                        <div class="col-auto">
                          <avatar :username="ticket.callerName" :size="36" :lighten="100"></avatar>
                        </div>
                        <div class="col">
                          <p class="mb-0 fw-bold">
                            {{ticket.callerName}}
                          </p>
                          <p v-if="ticket.customerId" class="mb-0 fst-italic text-secondary">
                            {{customerName(ticket.customerId)}}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td v-on:click="visualizaTicket(ticket)" class="cursor-pointer">
                      <span class="fw-bold">{{ticket.shortDescription}}</span>
                    </td>
                    <td v-on:click="visualizaTicket(ticket)" class="cursor-pointer">
                      {{formataAberto(ticket)}}
                      <p v-if="imOnCard(ticket)" class="mb-0 text-muted-2">
                        <font-awesome-icon :icon="['fas', 'user-check']" fixed-width/>
                      </p>
                    </td>
                    <td v-on:click="visualizaTicket(ticket)" class="cursor-pointer">
                      <span v-if="ticket.status === 'waitingFor'" class="badge rounded-pill bg-secondary">
                        Aguardando
                      </span>
                      <span v-if="ticket.status === 'etAberto' && !ticket.atendimento_iniciado" class="badge rounded-pill bg-warning">
                        Novo
                      </span>
                      <span v-if="ticket.status === 'etAberto' && ticket.atendimento_iniciado" class="badge rounded-pill bg-primary">
                        Em atendimento
                      </span>
                      <span v-if="ticket.status === 'etEncerrado'" class="badge rounded-pill bg-secondary">
                        Encerrado
                      </span>
                      <span v-if="isLate(ticket)" class="badge rounded-pill bg-warning">
                        Atrasado
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="small ms-1" />
                      </span>
                    </td>
                  </tr>
                </tbody>
            </table>
            <!-- TABELA 1 -->

            <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
          </div>
        </div>

      </div>
    </div>

    <!-- modal-novo-ticket -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-novo-ticket" id="btn-modal-novo-ticket-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-novo-ticket" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Novo ticket
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-2">
                <label>Quem solicitou o chamado? (não precisa ser o assinante)</label>
                <input v-model="modalNovoTicket.form.callerName" type="text" maxlength="50" class="form-control">
              </div>

              <div class="col-12 mb-2">
                <label>Contato para retornar se necessário (telefone, email, instagram, facebook, etc)</label>
                <input v-model="modalNovoTicket.form.contactForCallback" type="text" maxlength="32" class="form-control">
              </div>

              <div class="col-12 mb-2">
                <label>Descrição curta (apenas um título descritivo)</label>
                <input v-model="modalNovoTicket.form.shortDescription" type="text" maxlength="64" class="form-control">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-novo-ticket-fecha">Cancelar</button>
            <button type="button"
                v-on:click="modalNovoTicketCadastrarHandle()"
                v-bind:disabled="!modalNovoTicketPodeSalvar()"
                class="btn btn-success">
              Abrir novo chamado
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-novo-ticket -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'
import store from '@/store'
import Avatar from 'vue-avatar'
import alerta from '../modules/dialogo/alerta'
import shared from '../modules/shared'
// import pesquisaCliente from '@/modules/pesquisa-cliente'

export default {
  name: 'Helpdesk',

  components: {
    Paginador,
    Avatar,
  },

  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      meuID: 0,
      meuGrupoID: 0,
      tipoExibicao: '', //teMeusTickets, teDoMeuGrupo, teGrupo, pending, waitingFor, teAtrasados, done, teHistorico
      grupoExibicao: 0,
      tickets: [],
      listaGrupos: [],
      listaOperadores: [],
      clientes: [], // TODO: DEPRECATE
      customers: [],
      intervaloContadores: undefined,
      showMenu: false,
      counters: {
        meus_tickets: 0,
        meus_tickets_atrasados: 0,
        tickets_do_meu_grupo: 0,
        tickets_abertos: 0,
        tickets_aguardando: 0,
        tickets_atrasados: 0,
        tickets_encerrados: 0,
        groups: [],
      },
      modalNovoTicket: {
        form: {
          callerName: '',
          contactForCallback: '',
          shortDescription: '',
        }
      }
    }
  },

  methods: {
    carregaTickets() {
      loading(true);

      api.get(`/helpdesk/tickets?${this.paginador.requestParams}&${this.lastEndpoint}`).then(res => {
        loading(false);
        this.tickets = res.data.items;
        this.clientes = res.data.clientes;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    carregaMeuID() {
      const userData = store.getters['auth/getUserData'];

      this.meuID = userData.userId;
      this.meuGrupoID = userData.helpdeskGroupId;
    },


    carregaGrupos() {
      api.get(`/ticket-groups?paginate.cancel&orderBy["order"]=asc`).then(res => {
        this.listaGrupos = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaGrupos();
        }, 1000);
      });
    },

    carregaOperadores() {
      api.get(`/usuarios?paginate.cancel&orderBy[nome]=desc`).then(res => {
        this.listaOperadores = res.data.items;
      }).catch(() => {
        setTimeout(() => {
          this.carregaOperadores();
        }, 1000);
      });
    },

    onChangePaginacao() {
      // this.carregaTickets();
      this.listaTickets();
    },

    exibeMeusTickets() {
      this.showMenu = false;
      this.tipoExibicao = 'teMeusTickets';
      this.grupoExibicao = 0;
      this.lastEndpoint = `/tickets/my-tickets?`;
      this.listaTickets();
    },

    exibeTicketsDoMeuGrupo() {
      this.showMenu = false;
      this.tipoExibicao = 'teDoMeuGrupo';
      this.grupoExibicao = 0;
      this.lastEndpoint = `/tickets/my-group?`;
      this.listaTickets();
    },

    exibeTicketsDoGrupo(id) {
      this.showMenu = false;
      this.tipoExibicao = 'teGrupo';
      this.grupoExibicao = id;
      // TODO: filtros: `estagio[in]=etCriacao,etAberto&grupo_id=${id}`;
      if (id) {
        this.lastEndpoint = `/tickets?group_id=${id}&`;
      } else {
        this.lastEndpoint = `/tickets?group_id[null]&`;
      }
      this.listaTickets();
    },

    exibeTicketsAbertos() {
      this.showMenu = false;
      this.tipoExibicao = 'pending';
      this.grupoExibicao = 0;
      this.lastEndpoint = '/tickets?status=pending&orderBy[created_at]=asc&'
      this.listaTickets();
    },

    exibeTicketsAguardando() {
      this.showMenu = false;
      this.tipoExibicao = 'waitingFor';
      this.grupoExibicao = 0;
      this.lastEndpoint = '/tickets?status=waitingFor&'
      this.listaTickets();
    },

    exibeTicketsAtrasados() {
      this.showMenu = false;
      this.tipoExibicao = 'teAtrasados';
      this.grupoExibicao = 0;
      this.lastEndpoint = `/tickets/late?`;
      this.listaTickets();
    },

    exibeTicketsEncerrados() {
      this.showMenu = false;
      this.tipoExibicao = 'done';
      this.grupoExibicao = 0;
      this.lastEndpoint = '/tickets/done?'
      this.listaTickets();
    },

    exibeHistoricoTickets() {
      alerta("Função temporariamente desativada");
      return;
      // this.tipoExibicao = 'teHistorico';
      // this.grupoExibicao = 0;
      // this.lastEndpoint = `estagio=etEncerrado&arquivado=true`;
      // this.listaTickets();
    },

    atualizaContadores() {
      api.get(`/tickets/counters`).then(res => {
        this.counters = res.data;
      });
    },

    contadorGrupo(id) {
      const contador = this.counters.groups.find(x => x.id === id);
      if (!contador) {
        return 0;
      }
      return contador.total;
    },

    listaTickets() {
      // this.carregaTickets();
      loading(true);

      api.get(`${this.lastEndpoint}${this.paginador.requestParams}`).then(res => {
        loading(false);
        this.tickets = res.data.items;
        this.customers = res.data.aggregators.customers;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    visualizaTicket(ticket) {
      this.$router.push({ path: `/helpdesk/tickets/${ticket.id}/ver` });
    },

    formataAberto(ticket) {
      if (!ticket) {
        return '-';
      }

      const agora = new Date();
      const createdAt = new Date(ticket.createdAt);
      const diffTime = Math.abs(agora - createdAt);
      const diffSegundos = Math.floor(diffTime / (1000));
      const diffMinutos = Math.floor(diffTime / (1000 * 60));
      const diffHoras = Math.floor(diffTime / (1000 * 60 * 60));
      const diffDias = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDias > 0) {
        return diffDias +'d';
      }
      if (diffHoras > 0) {
        return diffHoras +'h';
      }
      if (diffMinutos > 0) {
        return diffMinutos +'m';
      }
      return diffSegundos +'s';
    },

    nomeOperador(id) {
      if (!id) {
        return '';
      }

      const op = this.listaOperadores.find(x => x.id === id);

      if (!op) {
        return '';
      }

      return op.nome;
    },

    customerName(id) {
      const customer = this.customers.find(x => x.id === id);

      if (!customer) {
        return '';
      }

      return customer.companyName || customer.name || '';
    },

    classeGrupoSelecionado(tipoExibicao, id) {
      if (this.tipoExibicao === tipoExibicao && this.grupoExibicao === id) {
        return ['menu-selecionado'];
      }

      return [];
    },

    imOnCard(ticket) {
      if (!ticket.usersId) {
        return false;
      }

      return ticket.usersId.some(x => x === this.meuID);
    },

    isLate(ticket) {
      if (ticket.status != 'pending') {
        return false;
      }

      const dueDate = shared.godate.toDate(ticket.dueDate);

      return (new Date() > dueDate);
    },

    ////////////////////////////////////////
    //modal-novo-ticket
    modalNovoTicketReset() {
      this.modalNovoTicket.form.callerName = '';
      this.modalNovoTicket.form.contactForCallback = '';
      this.modalNovoTicket.form.shortDescription = '';
    },

    modalNovoTicketAbre() {
      document.getElementById('btn-modal-novo-ticket-abre').click();
    },

    modalNovoTicketFecha() {
      document.getElementById('btn-modal-novo-ticket-fecha').click();
    },

    modalNovoTicketAbreHandle() {
      this.modalNovoTicketReset();
      this.modalNovoTicketAbre();
    },

    modalNovoTicketPodeSalvar() {
      switch (true) {
        case (this.modalNovoTicket.form.callerName.length < 3):
        case (this.modalNovoTicket.form.contactForCallback.length < 5):
        case (this.modalNovoTicket.form.shortDescription.length < 5):
          return false;

        default:
          return true;
      }
    },

    modalNovoTicketCadastrarHandle() {
      const form = this.modalNovoTicket.form;

      const sendData = {
        shortDescription: form.shortDescription,
        callerName: form.callerName,
        contactForCallback: form.contactForCallback,
      };

      loading(true);

      api.post('/tickets', sendData).then((res) => {
        loading(false);
        this.modalNovoTicketFecha();
        // this.carregaTickets();
        this.$router.push({ path: `/helpdesk/tickets/${res.data.id}/ver` });
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modalNovoTicketRemoverHandle(ticket) {
      dialogo.confirmacao(`Confirma remover o ticket <b>#${ticket.id}</b>?`).then(() => {
        loading(true);

        api.delete(`/helpdesk/tickets/${ticket.id}`).then(() => {
          loading(false);
          this.carregaTickets();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },
    //modal-novo-ticket
    ////////////////////////////////////////
  },

  created() {
    this.carregaMeuID();
    this.carregaGrupos();
    // this.carregaOperadores();
    // this.carregaTickets();
    this.exibeMeusTickets();
    this.atualizaContadores();
    this.intervaloContadores = setInterval(this.atualizaContadores, 10000);
  },
  destroyed() {
    clearInterval(this.intervaloContadores);
  },
}
</script>

<style scoped>
.tabela-tickets .item-ticket:hover {
  background-color:rgba(128, 128, 128, 0.1);
}

.lista-grupos .item-grupo:hover {
  background-color:rgba(128, 128, 128, 0.1);
}

.menu-selecionado {
  font-weight: bold;
  color: rgb(16, 82, 182) !important;
  background-color:rgba(16, 82, 182, 0.13);
}

.table-sm-pc .badge {
  font-size: 11px;
}

@media (max-width: 587px) {
  table.helpdesk-menu {
    width: calc(100vw - 44px);
  }

  .hide-mobile {
    display: none;
  }

  /* .show-mobile {
    display: unset;
  } */
}


@media (min-width: 588px) {
  table.helpdesk-menu {
    width: 170px;
  }

  .hide-mobile {
    display: unset;
  }

  .show-mobile {
    display: none;
  }
}
</style>